import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { CameraInfo } from '../../../models/camera/camera-info';
import { VehicleDvrVideoService } from '../../../service/vehicale-dvr-video.serevice';
import { Observable } from 'rxjs';
import { AddDeviceInputComponent } from './add-device-input.component';
import { map } from 'rxjs/operators';
import { DialogsService } from '@fc-core/services/dialogs.service';

@Component({
  selector: 'fc-add-dvr',
  template: `
    <fc-dialog-header
      [showCloseButton]="false"
      title="Add DVR"
    ></fc-dialog-header>
    <fc-dialog-content>
      <div class="add-device-container">
        <div class="add-device-content">
          <img
            class="add-device-image"
            src="assets/images/device-types/dvr.png"
            alt="dvr"
          />
          <div class="add-device-instruction">
            <div class="title h3">Simply connect your DVR</div>
            <div class="text">
              Connect the DVR by manually adding the serial number below.
            </div>
          </div>
        </div>

        <div class="add-device-inputs">
          <fc-add-device-input
            #cameraInput
            [requestFunction]="getDvrList"
            [verifiedFunction]="verifyDvr"
            [title]="'Camera number'"
            [serialLength]="13"
          ></fc-add-device-input>
        </div>
      </div>
    </fc-dialog-content>
    <fc-dialog-footer
      [secondaryText]="!addOnly ? 'Delete' : null"
      (secondaryClicked)="confirmDelete()"
      primaryText="Add"
      [disabled]="!valid"
      (primaryClicked)="onAddDevice()"
      [cancelText]="addOnly ? 'Cancel' : 'Back'"
      [cancelFn]="onBackButton.bind(this)"
    ></fc-dialog-footer>
  `,
  styleUrls: ['./add-device.scss'],
  standalone: false,
})
export class AddDvrComponent implements OnChanges {
  @ViewChild('cameraInput', { static: true })
  cameraInput: AddDeviceInputComponent<CameraInfo>;
  @Input() selectedDvr: CameraInfo;
  @Input() vehicleId: number;
  @Input() addOnly: boolean;
  @Output() back = new EventEmitter<void>();
  @Output() addDevice = new EventEmitter<{ camera: CameraInfo }>();

  constructor(
    private vehicleDvrVideoService: VehicleDvrVideoService,
    private dialogService: DialogsService,
  ) {}

  ngOnChanges() {
    if (this.selectedDvr) {
      this.cameraInput.itemsControl.setValue(this.selectedDvr);
    }
  }

  get valid(): boolean {
    return this.cameraInput.itemsControl.valid;
  }

  onAddDevice() {
    this.addDevice.emit({
      camera: this.cameraInput.itemsControl.value as CameraInfo,
    });
  }

  getDvrList = (search: string): Observable<CameraInfo[]> => {
    return this.vehicleDvrVideoService
      .getCameraList()
      .pipe(map((cameraList) => this.filterCameraList(cameraList, search)));
  };

  verifyDvr = (serial: string): Observable<CameraInfo> => {
    return this.vehicleDvrVideoService.validateCamera(serial, this.vehicleId);
  };

  confirmDelete() {
    this.dialogService.openConfirmDialog({
      title: 'Delete DVR?',
      message: 'Are you sure you want to delete this DVR?',
      buttonText: 'Delete',
      action: () =>
        this.addDevice.emit({
          camera: null,
        }),
      buttonColor: 'warn',
    });
  }

  private filterCameraList(
    cameraList: CameraInfo[],
    search: string,
  ): CameraInfo[] {
    return cameraList.filter(
      (camera) =>
        camera.assigned === false &&
        camera.serial.toLowerCase().includes(search.toLowerCase()),
    );
  }

  onBackButton() {
    this.back.emit();
  }
}
